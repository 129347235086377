import request from "@/utils/request";

// 我们的售后与保修政策
export function Guarantee(id) {
  return request.get(`/goods/serviceSupport/getById/${id}`);
  // return request.get(`/serviceSupport/getById/${id}`);
}

// 标题列表
export function TitleType() {
  return request.get("/goods/serviceSupport/listType");
  // return request.get("/serviceSupport/listType");
}

export function detail(id) {
   return request.get(`/goods/serviceSupport/query/product/data/detail/${id}`);
  // return request.get(`/serviceSupport/getById/${id}`);
}
export function queryDetail(id) {
   return request.get(`/order/after/sale/query/my/detail/${id}`);
  // return request.get(`/serviceSupport/getById/${id}`);
}
  

export function listProvince() {
  return request.get("/goods/serviceSupport/listProvince");
}

export function listCity(province) {
  return request.get("/goods/serviceSupport/listCity/" + province);
}

export function byOptionslist(data) {
  return request.post("/goods/production/query/list/byOptions",data);
}

export function byoptions() {
  return request.post("/goods/production/query/options");
}

export function listArea(city) {
  return request.get("/goods/serviceSupport/listArea/" + city);
}

export function pageListSite({ pageNum, pageSize, province, city, area }) {
  return request.get("/goods/serviceSupport/pageListSite", {
    pageNum,
    pageSize,
    province,
    city,
    area,
  });
}

// 文章详情
export function ArticleDetails({ pageNum, pageSize, typeName, title }) {
  return request.get("/goods/serviceSupport/pageListServiceSupport", {
    pageNum,
    pageSize,
    typeName,
    title,
  });
}
export function productDetails({ pageNum, pageSize, title }) {
  return request.get("/goods/serviceSupport/query/product/data", {
    pageNum,
    pageSize,
    title,
  });
}

// 查询售后单号
export function repairerPhoneData({
  afterSaleOrderNumber,
  repairer,
  repairerPhone,
  masterSnCode
}) {
  return request.get("/order/after/sale/query/after/sale/order", {
    afterSaleOrderNumber,
    repairer,
    repairerPhone,
    masterSnCode
  });
}
